module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156119853-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Noortje Knulst","short_name":"NK","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"661f762f2dc8c47d8ab0bd0ef61ca906"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
